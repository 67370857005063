<template>
    <div class="profleInfo paymentHistory">
        <h3>Payment History</h3>
        <span class="section-border"></span>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Payment Date</th>
                        <th>Payment Type</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="data.length > 0"> 
                        <tr v-for="(item, i) in data" :key="i"> 
                            <td>{{item.order_id}}</td>
                            <td>{{item.order_date}}</td>
                            <td>{{item.payment_type}}</td>
                            <td>{{ currency }} {{ item.total_amount - item.discount_amount }}</td>
                            <td>
                                <div class="table-actions">
                                    <ul>
                                        <li>
                                            <a @click="viewInvoice(item)" v-b-tooltip.hover title="View Invoice">
                                                <span class="icon-view"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr> 
                    </template>
                    <template v-else > 
                        <tr><td colspan="5">Not found.</td></tr>
                    </template>
                </tbody>
            </table>
        </div>
        <!-- Invoice Modal -->
        <template>
            <b-modal id="invoice-modal" size="lg" centered>
                <div id="element-to-convert">
                    <div class="main-app">
                        <div class=" row big-top-margin">
                            <div class="main-content">
                                <div class="customer">
                                    <span class="light-grey"><strong> Invoice No:</strong> {{item.order_id}}</span>
                                    <span class="bold-font text-uppercase"> {{user.name}} </span>
                                    <span> {{user.address}} </span>
                                    <span> {{user.mobile_number}} </span>
                                </div>
                                <div class="inv">
                                    <span class="bold-font text-uppercase"> <strong> {{company.site_title}} </strong></span>
                                    <span> {{company.site_address}} </span>
                                    <span> {{company.site_email}}  </span>
                                    <span> {{company.site_phone}}  </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="item-content">
                                <table class="inv-table" aria-colcount="4">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(value,i) in item.items" :key="i">
                                            <td>{{value.title}}</td>
                                            <td>{{value.display_price}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="summary">
                                    <div class="">
                                        <span>Sub Total</span>
                                        <span>{{ currency }} {{item.total_amount}}</span>
                                    </div>
                                    <div class="">
                                        <span>Discount</span>
                                        <span>{{ currency }} {{item.discount_amount}}</span>
                                    </div>
                                    <div class=" bold-font">
                                        <span>Total</span>
                                        <span> {{ currency }} {{item.total_amount - item.discount_amount}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="univ-btn text-center" @click='generatePDF(item.order_id)'> Download PDF </button>
            </b-modal>
        </template>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
import store from "@/store"
import html2pdf from "html2pdf.js";
export default{
    name: 'PaymentHistory',
    data() {
        return {
            data: [],
            itemList:[],
            filterData:{
                user_id:store.getters.user.id,
                sort:"latest",
            },
            user:{
                name: store.getters.user.name,
                email: store.getters.user.email,
                mobile_number: store.getters.user.mobile_number,
                address: store.getters.user.address,
            },
            item:[],
            currency: store.getters.currency,
            company:[],
        };
    },
    mounted(){
        let app = this;
        app.renderItems();
        app.renderCompanyInfo();
    },
    methods: {
        renderItems(){
            let app = this;
            Helper.showSpinner();
            axios.post(api.getUrl('/user/history'), app.filterData).then(function (response) {
                if(response.data.success){
                    app.pageData = false;
                    app.data = response.data.data;
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        viewInvoice(item){
            let app = this;
            app.item = item;
            app.$bvModal.show('invoice-modal');
        },
        generatePDF(orderId) {
            html2pdf(document.getElementById("element-to-convert"), {
                margin: 1,
                filename: orderId+".pdf",
            });
        },
        renderCompanyInfo(){
            let app = this;
            axios.get(api.getUrl('/company-info')).then(function (response) {
                if(response.data.success){
                    app.company = response.data.data;
                }
            });
        },
    }
}
</script>